import * as React from "react"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import logo from "../images/logo.svg"
import OpenTable from "react-opentable"

// data
const links = [
  {
    title: "Back home",
    url: "/",
  },
]

// markup
const BookPage = () => {
  return (
    <>
      <SEO title="Make a booking" desc="" />
      <main className="relative min-h-screen flex flex-col">
        <section className="py-8 md:py-16 max-w-5xl w-full mx-auto px-4 flex justify-center items-center">
          <Link to="/">
            <img
              src={logo}
              className="pointer-events-none h-24 md:h-32"
              alt="RB Dining Logo"
            />
          </Link>
        </section>
        <section className="py-8 md:py-16 w-full max-w-5xl mx-auto px-4 flex justify-center">
          <OpenTable rid="171602" />
        </section>
        <nav className="mt-auto py-8 md:py-16 w-full max-w-5xl mx-auto px-4 text-center">
          <ul className="flex flex-col justify-center items-center md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            {links.map((data, key) => {
              return (
                <li key={key}>
                  <Link
                    to={data.url}
                    className="inline-block font-bold uppercase tracking-wide text-sm px-8 py-3 bg-white text-dark hover:opacity-75 transition duration-200"
                  >
                    {data.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </main>
    </>
  )
}

export default BookPage
